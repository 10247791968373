import JSDB from '../fromKotlin/nk';

interface Customer {
    active: boolean;
    id: string;
    name: string;
    email: string;
    agent: string;
    phone: string;
    phone2: string;
    phone3: string;
    // Print State
    withBarcode: boolean;
    withReturns: boolean;
    withPrice: boolean;
    position: number;
    date?: Date;
    notes2: string;
    notes3: string;
    notes4: string;
    days_to_pay: number;
    // Address
    date_contact?: string;
    city: string;
    street: string;
    streetNumber: string;

    category: string;
    group?: string;
    paymentComments?: string;


    // Aux
    includeTax: boolean;
    noTaxClient: boolean;
    discount: number;
    min_order: number;
    min_order_sum: number;
    net_split: number;
    location: string;

    //
    contactName: string;
    contactName2: string;
    contactName3: string;
    businessId: string;
    businessName: string;
    businessName2?:string
    externalId: string;
    comments?: string;
    obligo: number;
    tax_note_type: number;
    priceControl: number;

    sunday: boolean | true;
    monday: boolean | true;
    tuesday: boolean | true;
    wednesday: boolean | true;
    thursday: boolean | true;
    friday: boolean | true;
    notes: boolean | true;
    visit: boolean | false;
    collector: string | undefined | null;
    driver: string | undefined | null;

    // external_data
    status?: string;
    edi_sup_id?: string,
    edi_client_id?: string,
    net_edi?: string,
    internal_sup_edi?: string,
    branch_number?: string

    nkObject: any;
}

// SERIALIZERS
export const serializePrintState = (c: Customer): number => Number(c.withBarcode) * 1 + Number(c.withReturns) * 2 + Number(c.withPrice) * 4;
export const serializeDays = (c: Customer): number => Number(c.sunday) + Number(c.monday) * 2 + Number(c.tuesday) * 4 + Number(c.wednesday) * 8 + Number(c.thursday) * 16 + Number(c.friday) * 32;
export const serializeAddress = (c: Customer): string =>
    c.city.length === 0 && c.street.length === 0 && c.streetNumber.length === 0
        ? ''
        : `${c.city},${c.street},${c.streetNumber.toString()}`.replace('"', '');
export const serializeTax = (c: Customer) => Number(c.includeTax);
export const serializeNoTaxClient = (c: Customer) => Number(c.noTaxClient);

// DE-SERIALIZERS
export const deserializePrintState = (ps: number): { withBarcode: boolean; withReturns: boolean; withPrice: boolean } => ({
    withBarcode: Boolean(ps & 1),
    withReturns: Boolean(ps & 2),
    withPrice: Boolean(ps & 4)
});


export const deserializeTax = (t: number) => Boolean(t);

export const deserializeCustomer = (c): Customer => {
    let status: string | undefined = '';
    if (JSDB().hasBeenToday(c)) {
        status = 'חולק';
    } else if (JSDB().hasOrderToday(c)) {
        status = 'הזמנה פתוחה';
    }
    let cust =  makeCustomer({
        active: c.active === 1,
        id: c.id,
        name: c.getName(),
        email: c.getEmail(),
        agent: c.getAgent(),
        phone: c.getPhone(),
        phone2: c.getSecondPhone(),
        phone3: c.getThirdPhone(),

        // Print State
        ...deserializePrintState(c.print_state),
        position: c.position,
        date: c.creation_time ? new Date(c.creation_time) : undefined,
        // Address
        city: c.getCity(),
        street: c.getStreet(),
        streetNumber: c.getStreetNumber(),
        category: c.category,
        days_to_pay: c.days_to_pay,
        notes2: c.notes2,
        notes3: c.notes3,
        notes4: c.notes4,
        date_contact: c.date_contact ? (c.date_contact) : undefined,


        // Aux
        includeTax: deserializeTax(c.getIncludeTax()),
        noTaxClient: deserializeTax(c.getNoTaxClient()),
        discount: c.getDiscount(),
        priceControl: c.price_control,

        //
        contactName: c.getPhoneContact(),
        contactName2: c.getSecondPhoneContact(),
        contactName3: c.getThirdPhoneContact(),
        businessId: c.getBusinessId(),
        businessName: c.getBusinessName(),
        businessName2: c.business_name,
        externalId: c.getExternalId(),
        status,
        location: c.location,
        sunday: c.isSunday(),
        monday: c.isMonday(),
        tuesday: c.isThusday(),
        wednesday: c.isWendesday(),
        thursday: c.isThursday(),
        friday: c.isFriday(),
        notes: c.getNotes() === 1,
        visit: c.getNotes() === -1,
        collector: c.getCollector()?.user_name,
        driver: c.getDriver()?.user_name,
        comments: c.comments,
        // min_order:c.getMinOrder(),
        min_order: c.min_order ?? 0,
        min_order_sum: c.min_order_sum ?? 0,
        edi_sup_id: c.edi?.edi_sup_id,
        edi_client_id: c.edi?.edi_client_id,
        net_edi: c.edi?.net_edi,
        internal_sup_edi: c.edi?.internal_sup_edi,
        branch_number: c.edi?.branch_number,
        obligo: c.getObligo(),
        group: c.category2,
        net_split: c.net_split,
        paymentComments: c.payment_notes,
        tax_note_type: c.tax_note_type,
        nkObject: c
    });
    cust.freeDay = c.days<0
    return cust
};


export const makeCustomer = ({
                                 active = false,
                                 id = '',
                                 name = '',
                                 email = '',
                                 agent = '',
                                 phone = '',
                                 phone2 = '',
                                 phone3 = '',

                                 // Print State
                                 withBarcode = false,
                                 withReturns = false,
                                 withPrice = false,
                                 position = 0,
                                 date = undefined,
                                 // Address
                                 city = '',
                                 street = '',
                                 streetNumber = '',
                                 category = '',
                                    days_to_pay = 0,
                                    notes2 = '',
                                    notes3 = '',
                                    notes4 = '',
                                    date_contact = undefined,

                                 // Aux
                                 includeTax = false,
                                 noTaxClient = false,
                                 discount = 0,

                                 //
                                 contactName = '',
                                 contactName2 = '',
                                 contactName3 = '',
                                 businessId = '',
                                 businessName = '',
                                 businessName2 = '',
                                 externalId = '',
                                 status = '',
                                 sunday = true,
                                 monday = true,
                                 tuesday = true,
                                 wednesday = true,
                                 thursday = true,
                                 friday = true,
                                 notes = true,
                                 visit=false,
                                 collector = '',
                                 driver = '',
                                 comments = '',
                                 min_order = 0,
                                 min_order_sum = 0,
                                 location = "",
                                 edi_sup_id = undefined,
                                 edi_client_id = undefined,
                                 net_edi = undefined,
                                 internal_sup_edi = undefined,
                                 branch_number = undefined,
                                 obligo=0,
                                 priceControl = 0,
                                 paymentComments=undefined,
                                    group=undefined,
                                 tax_note_type=0,
                                 net_split=0,
                                 nkObject = null
                             }): Customer => ({
    active,
    id,
    name,
    email,
    agent,
    phone,
    phone2,
    phone3,
    withBarcode,
    withReturns,
    withPrice,
    position,
    date,
    city,
    street,
    streetNumber,
    includeTax,
    noTaxClient,
    discount,
    contactName,
    contactName2,
    contactName3,
    businessId,
    businessName,
    businessName2,
    externalId,
    status,
    sunday,
    monday,
    tuesday,
    wednesday,
    thursday,
    friday,
    notes,
    visit,
    collector,
    driver,
    comments,
    min_order,
    min_order_sum,
    location,
    category,
    edi_sup_id,
    edi_client_id,
    net_edi,
    internal_sup_edi,
    branch_number,
    obligo,
    priceControl,
    paymentComments,
    group,
    tax_note_type,
    days_to_pay,
    notes2,
    notes3,
    notes4,
    date_contact,
    net_split,
    nkObject
});

export default Customer;

import React from 'react';
import {useParams} from 'react-router-dom';
import {useDispatch, useSelector} from 'react-redux';
import {selectCustomerById} from 'store/customers/customersSlice';
import {useCreateSupplierDocument} from 'hooks/customer/documents.hooks';

// material-ui
import {useTheme} from '@mui/material/styles';
import {Box, Grid, TextField, Typography} from '@mui/material';
import LoadingButton from '@mui/lab/LoadingButton';
import MainCard from 'ui-component/cards/MainCard';

// locale
// project imports
import {DocumentType, ProductNote} from '../../../../types/DocumentsTypes/Document';

// types
import {createDocument} from 'types/DocumentsTypes/utils';
import IItem from 'types/product_delivery';

import Swal from 'sweetalert2';
import {getSpecificSupplier, selectSupplierById} from '../../../../store/suppliers/suppliersSlice';
import {dateObjectToString} from '../../../../fromKotlin/nkutils';
import {getSupplierProductsPrices} from '../../../../api/customer/products_prices.api';
import JSDB, {NKTypeConvertor} from '../../../../fromKotlin/nk';
import {collect} from '../../../../api/customer/documents.api';
import DocumentSupplierDetails from '../DocumentSupplierDetails';
import CustomerProductsList from '../../../customer/profile/products/CustomerProductsList';
import DocumentPreview from 'views/customer/profile/documents/DocumentPreview';
import {RootState} from '../../../../store';


const NewSupNote = ({ upid, narrow }: { upid: string, narrow?: boolean }) => {
    const theme = useTheme();
    const { id } = useParams();
    const dispatch = useDispatch();
    const documentType: DocumentType = 'supplier_note'
    // TODO: Change to single supplier fetching
    const supplier = documentType == 'supplier_note' ? useSelector((state: RootState) => selectSupplierById(state, id ?? upid)) : useSelector((state: RootState) => selectCustomerById(state, id ?? upid));
    React.useEffect(() => {
        if (!supplier) dispatch(getSpecificSupplier(Number(id)));
    }, [dispatch]);
    const [date, setDate] = React.useState<Date>(new Date());
    const [products, setProducts] = React.useState<IItem[]>([]);
    const [bul, setBul] = React.useState<number>();
    const [autoNum, setAutoNum] = React.useState<number | undefined>(undefined);

    const [mainValue, setMainValue] = React.useState<number>(0);
    const [tax, setTaxValue] = React.useState<number>(0);
    const [getAllProducts, setGetAllProducts] = React.useState<boolean>(false);
    const [commentControl, setCommentControl] = React.useState<any>({
        notes: '',
        notes2: '',
        notes3: '',
        notes4: '',
        notes5: '',
    });
    React.useEffect(() => {
        const genProds = async () => {
            const ps = await getSupplierProductsPrices(id ?? upid, dateObjectToString(date), getAllProducts);
            setProducts(ps);
        };
        genProds();
    }, [date, getAllProducts]);
    let counter = 0;
    const onProductChange = React.useCallback((product: IItem) => {
        setProducts((prevProducts) => {
            let seen = false;
            const x = prevProducts.map((p) => {
                if (product.id === p.id) {
                    seen = true;
                    return product;
                }
                return p;
            }
            );
            if (!seen) {
                x.push(product);
            }
            if (product.notePlacement == undefined) {
                product.notePlacement = counter;
                counter += 1;
            }
            return x;
        }
        );
    }, []);
    const clearProducts = React.useCallback(() => {
        setProducts((prevProducts) => prevProducts.map((p) => ({ ...p, quantity: 0, quantityReturns: 0 })));
    }, []);

    React.useEffect(() => {
        const pr = products.filter((p) => p.quantity > 0 || p.quantityReturns > 0 || (p.quantitySecond > 0 && (p.conversion_ratio > 0 )));
        if (pr.length === 0) {
            setMainValue(0);
            setTaxValue(0);
        }
        const result = JSDB().productDeliveryToAmount(NKTypeConvertor().toKotlinList(collect(pr, Number(id ?? upid), false, false)), Number(id ?? upid), dateObjectToString(date),false);

        setMainValue(JSDB().round(result.third, 2));
        setTaxValue(JSDB().round(result.second, 2));

    }, [products]);
    const commentchange = (e) => {
        setCommentControl({notes:e.target.value});
    };
    const createSupplierDocument = useCreateSupplierDocument(id ?? upid);
    const onProductChangeReal = (createSupplierDocument.data) ? undefined : onProductChange;
    if (!supplier || (!id && !upid)) return <Box />;
    return (
        <MainCard
            title={
                <Typography variant='h3'>
                    תעודת ספק חדשה
                </Typography>
            }
        >
            <Grid container spacing={3}>
                {createSupplierDocument.data === undefined && !narrow ?
                    <Grid item xs={7}>
                        <Grid container spacing={2}>
                            <Grid item xs={12} sm={12}>
                                <DocumentSupplierDetails supplier={supplier} date={date} setDate={setDate} />
                            </Grid>
                            <Grid item xs={12} sm={12}>
                                <Typography variant='h4'>
                                    פרטים נוספים
                                </Typography>
                            </Grid>
                            <Grid item xs={6} sm={6}>
                                <TextField
                                    fullWidth
                                    id='comment'
                                    name='comment'
                                    label='הערות'
                                    value={commentControl.notes}
                                    onChange={commentchange}
                                />
                            </Grid>
                            <Grid item xs={6} sm={6}>
                                <TextField
                                    fullWidth
                                    id='bul'
                                    name='bul'
                                    label='מספר תעודה אצל ספק'
                                    type='number'
                                    onChange={(v) => {
                                        setBul(Math.floor(Number(v.target.value)));
                                    }}
                                    value={bul}
                                    onKeyDown={(e) => {
                                        if (e.keyCode === 38 || e.keyCode === 40) {
                                            e.preventDefault();
                                        }
                                    }
                                    }
                                    onWheel={(e) => e.target.blur()}
                                />
                            </Grid>
                            <Grid item xs={12} sm={12} md={12} alignItems='center'>
                                <CustomerProductsList getAllProducts={getAllProducts} setGetAllProducts={setGetAllProducts} products={products} onProductChange={onProductChange}
                                    clearFunc={clearProducts}

                                    withReturns={true} />
                            </Grid>
                        </Grid>
                    </Grid> : undefined
                }
                <Grid item xs={narrow ? 12 : 5}>
                    <Grid container>
                        <Grid item xs={12} sm={12}>
                            <DocumentPreview
                                outerDoc={createSupplierDocument.data ? undefined : setCommentControl}
                                document={
                                    {
                                        entId: supplier.id,
                                        date: date ?? new Date(),
                                        type: documentType,
                                        products:
                                            ((createSupplierDocument.data) ?
                                                ((createSupplierDocument.data as ProductNote[])[0].products) : products)
                                                .filter((p) => p.quantity > 0 || p.quantityReturns > 0 || p.forceShow),
                                        status: ((createSupplierDocument.data) ?
                                            ((createSupplierDocument.data as ProductNote[])[0].status) : 'pending_approval'),
                                        id: ((createSupplierDocument.data) ?
                                            ((createSupplierDocument.data as ProductNote[])[0].id) : autoNum ?? 0),
                                        end: new Date(),
                                        color: '',
                                        textColor: '',
                                        baseColor: '',
                                        description: '',
                                        title: 'תעודת ספק חדשה',
                                        allDay: false,
                                        nkObject: ((createSupplierDocument.data) ?
                                            ((createSupplierDocument.data as ProductNote[])[0].nkObject) : undefined),
                                        onProductChange: onProductChangeReal,
                                        onDateChange: (createSupplierDocument.data) ? undefined : setDate,
                                        onIdChange: (createSupplierDocument.data) ? undefined : setAutoNum,
                                        agent: JSDB().currentAgent,
                                        issueDate: new Date(),
                                        name: '',
                                        mainValue: mainValue,
                                        taxValue: tax,
                                        comment: commentControl.notes,

                                        bul: bul
                                    }
                                }
                            />
                        </Grid>
                        <Grid item xs={12} sm={12}>
                            <Box
                                sx={{ display: 'flex', flex: 1, flexDirection: 'row', justifyContent: 'space-evenly' }}>
                                <LoadingButton
                                    onClick={() =>
                                        narrow ? createSupplierDocument.mutate(
                                            createDocument({
                                                mainValue: 0,
                                                id: autoNum,
                                                date: date ?? new Date(),
                                                type: documentType,
                                                products: products.filter((p) => p.quantity > 0 || p.quantityReturns > 0 || (p.quantitySecond > 0 && (p.conversion_ratio > 0 ))),
                                                entId: id,
                                                status: 'approved',
                                                comment:commentControl.notes,
                                                bul
                                            })
                                        ) :
                                            Swal.fire({
                                                title: 'האם תרצה להנפיק מסמך?',
                                                icon: 'warning',
                                                showCancelButton: true,
                                                confirmButtonColor: theme.palette.primary.dark,
                                                cancelButtonColor: theme.palette.error.light,
                                                confirmButtonText: 'הנפק',
                                                cancelButtonText: 'בטל',
                                                allowEnterKey: true,
                                                allowEscapeKey: true,
                                                keydownListenerCapture: true,
                                                focusConfirm: true
                                            }).then((result) => {
                                                if (result.isConfirmed) {
                                                    createSupplierDocument.mutate(
                                                        createDocument({
                                                            mainValue: 0,
                                                            date: date ?? new Date(),
                                                            type: documentType,
                                                            products: products.filter((p) => p.quantity > 0 || p.quantityReturns > 0 || (p.quantitySecond > 0 && (p.conversion_ratio > 0 ))),
                                                            entId: id,
                                                            id: autoNum,
                                                            status: 'approved',
                                                            comment:commentControl.notes,
                                                            bul
                                                        })
                                                    );
                                                }
                                            })
                                    }
                                    loading={createSupplierDocument.isLoading}
                                    disabled={createSupplierDocument.isSuccess || products.filter((p) => p.quantity > 0 || p.quantityReturns > 0 || (p.quantitySecond > 0 && (p.conversion_ratio > 0 ))).length === 0}
                                    variant='contained'
                                    type='submit'
                                    fullWidth
                                    sx={{ m: 2 }}
                                >
                                    הנפק
                                </LoadingButton>
                            </Box>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </MainCard>
    );
};
export default NewSupNote;

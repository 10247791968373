import { createSlice, createSelector, createAsyncThunk, createEntityAdapter } from '@reduxjs/toolkit';
import { RootState } from 'store';
import JSDB from 'fromKotlin/nk';
import { AsyncStatus } from 'store/types/asyncState';
import Agent, { deserializeAgent } from '../../types/agent';
import { applyFilters } from 'utils/sort';
import { Filter } from 'types';
import { addNewAgent } from '../../api/users/users.api';

const agentsAdapter = createEntityAdapter<Agent>({
    selectId: (a) => a.name,
    sortComparer: (c1, c2) => c1.name.localeCompare(c2.name)
});

// THUNKS
export const getAgents = createAsyncThunk('agents/getAgents', async () => JSDB().getAgents());
export const newAgents = createAsyncThunk('agents/newAgents', async (a: Agent) => addNewAgent(a));

const initialState: {
    getAgentsAsyncState: AsyncStatus;
    newAgentsAsyncState: AsyncStatus;
    agentsFilterBy: Filter[];
    driversFilterBy: Filter[];
    collectorsFilterBy: Filter[];
} = {
    getAgentsAsyncState: 'idle',
    newAgentsAsyncState: 'idle',
    agentsFilterBy: [],
    driversFilterBy: [],
    collectorsFilterBy: []
};

export const agentsSlice = createSlice({
    name: 'agents',
    initialState: agentsAdapter.getInitialState({
        ...initialState
    }),
    reducers: {
        initGetAgentsAsyncState: (state) => {
            state.getAgentsAsyncState = 'idle';
        },
        initNewAgentsAsyncState: (state) => {
            state.newAgentsAsyncState = 'idle';
        },
        // applySort: (state, action) => {
        //     state.sortBy = action.payload;
        // },
        applyAgentsFilter: (state, action) => {
            state.agentsFilterBy = state.agentsFilterBy.reduce(
                (acc, f: Filter) => (f.property === action.payload.property ? acc : acc.concat(f)),
                [action.payload]
            );
        },
        removeAgentsFilter: (state, action) => {
            state.agentsFilterBy = state.agentsFilterBy.filter((f) => f.property !== action.payload.property);
        },
        applyDriversFilter: (state, action) => {
            state.driversFilterBy = state.driversFilterBy.reduce(
                (acc, f: Filter) => (f.property === action.payload.property ? acc : acc.concat(f)),
                [action.payload]
            );
        },
        removeDriversFilter: (state, action) => {
            state.driversFilterBy = state.driversFilterBy.filter((f) => f.property !== action.payload.property);
        },
        applyCollectorsFilter: (state, action) => {
            state.collectorsFilterBy = state.collectorsFilterBy.reduce(
                (acc, f: Filter) => (f.property === action.payload.property ? acc : acc.concat(f)),
                [action.payload]
            );
        },
        removeCollectorsFilter: (state, action) => {
            state.collectorsFilterBy = state.collectorsFilterBy.filter((f) => f.property !== action.payload.property);
        }
    },
    extraReducers: (builder) => {
        builder
            /* GET_AGENTS STATE */
            .addCase(getAgents.pending, (state) => {
                if (state.getAgentsAsyncState === 'idle') {
                    state.getAgentsAsyncState = 'loading';
                }
            })
            .addCase(getAgents.fulfilled, (state, action) => {

                state.getAgentsAsyncState = 'succeeded';
                if (action.payload.first.size > 0)
                    agentsAdapter.setAll(
                        state,
                        action.payload.first.toArray().map((a) => deserializeAgent(a))
                    );

            })
            .addCase(getAgents.rejected, (state, action) => {
                if (state.getAgentsAsyncState === 'loading') {
                    state.getAgentsAsyncState = 'failed';
                }
            })
            .addCase(newAgents.pending, (state) => {
                if (state.newAgentsAsyncState === 'idle') {
                    state.newAgentsAsyncState = 'loading';
                }
            })
            .addCase(newAgents.fulfilled, (state, action) => {
                if (state.newAgentsAsyncState === 'loading') {
                    state.newAgentsAsyncState = 'succeeded';
                    agentsAdapter.addOne(state, deserializeAgent(action.payload.first));
                }
            })
            .addCase(newAgents.rejected, (state, action) => {
                if (state.newAgentsAsyncState === 'loading') {
                    state.newAgentsAsyncState = 'failed';
                }
            });


    }
});

// actions
export const {
    initGetAgentsAsyncState,
    initNewAgentsAsyncState,
    applyAgentsFilter,
    removeAgentsFilter,
    applyDriversFilter,
    removeDriversFilter,
    applyCollectorsFilter,
    removeCollectorsFilter
} = agentsSlice.actions;

// Selectors
export const selectGetAgentsAsyncState = (state: RootState) => state.agents.getAgentsAsyncState;
export const selectAgentsFilterBy = (state: RootState) => state.agents.agentsFilterBy;
export const selectDriversFilterBy = (state: RootState) => state.agents.driversFilterBy;
export const selectCollectorsFilterBy = (state: RootState) => state.agents.collectorsFilterBy;

export const {
    selectAll: selectAgents,
    selectById: selectAgentById
} = agentsAdapter.getSelectors((state: RootState) => state.agents);

// Memoized Selectors
export const selectAgentsNames = createSelector(selectAgents, (agents) => agents.filter((a) => a.isAgent).map((a) => a.name));

export const allAgents = createSelector(selectAgents, (_, isActive) => isActive, (agents, isActive) => {
    return agents.filter((a) => (isActive && a.active) || (!isActive && !a.active))
});

export const selectLines = createSelector(selectAgents, (agents) => agents.filter((c) => c.isAgent));
export const selectLinesWithFilter = createSelector(selectLines, selectAgentsFilterBy, (agents, filterBy) =>
    applyFilters(agents, filterBy)
);


export const selectIncreaseAgent = createSelector(selectAgents, (agents) => agents.filter((c) => c.increase_id && c.active))
export const selectDrivers = createSelector(selectAgents, (agents) => agents.filter((c) => c.isDriver && c.active))
export const selectDriversNames = createSelector(selectDrivers, (agents) => agents.map((a) => a.name));
export const selectDriversWithFilter = createSelector(selectDrivers, selectDriversFilterBy, (drivers, filterBy) =>
    applyFilters(drivers, filterBy)
);

export const selectCollectors = createSelector(selectAgents, (agents) => agents.filter((c) => c.isCollector && c.active));
export const selectCollectorsWithFilter = createSelector(selectCollectors, selectCollectorsFilterBy, (collectors, filterBy) =>
    applyFilters(collectors, filterBy)
);

export default agentsSlice.reducer;

import * as React from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {
    deleteCustomer,
    fillCustomers,
    getCustomers,
    getCustomersCreation,
    removeAllFilters,
    selectCustomersObjs,
    selectGetCustomersAsyncState, selectPriceMenus
} from 'store/customers/customersSlice';

// locale
import {FormattedMessage} from 'react-intl';
import {useTheme} from '@mui/material/styles';

// project imports
import AddCustomer from './AddCustomer';
import Loader from 'ui-component/Loader';

// types
import {HeadCell} from 'types';
import {
    AppBar,
    Avatar,
    Box,
    Chip,
    CircularProgress,
    Dialog,
    IconButton,
    Slide,
    Toolbar,
    Tooltip,
    Typography
} from '@mui/material';
import {callbackNetworkResult, fetchMimshakClient, getFile, load_from_xlsx} from '../../api/customer/files.api';
import {TransitionProps} from '@mui/material/transitions';
import {cloneDeep} from 'lodash';
import AgTable, {getSelectedRows, ITableControls} from 'ui-component/ag-grid/Ag-Table';
import {IconEdit, IconId, IconInfoCircle, IconPhone, IconTrash} from '@tabler/icons';
import MyChip from 'ui-component/extended/Chip';
import PeopleIcon from '@mui/icons-material/People';
import {Mail, MapPin, User} from 'tabler-icons-react';
import Swal from 'sweetalert2';
import CustomerPreview from './profile/documents/CustomerPreview';
import CloseIcon from '@mui/icons-material/Close';
import CustomerStatus from './properties/CustomerStatus';
import dayjs from 'dayjs';
import {getAgents, selectDrivers, selectLines} from "../../store/agents/agentsSlice";
import Agent from "../../types/agent";
import RestartAltIcon from '@mui/icons-material/RestartAlt';
import AgTableContainer from 'ui-component/ag-grid/table-container';
import {changeAllCustomers} from "../../api/customer";
import CreateBranchDialog from './CreateBranchDialog';
import {customGroup, customGroupActiveForChip, customGroupLabelForChip} from 'ui-component/ag-grid/ag-table-utils';
import DialogUploadFile from "../../ui-component/dialogs/uploadFileDialog";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import JSDB from 'fromKotlin/nk';
import {dateObjectToString, dateObjectToStringIsrael} from 'fromKotlin/nkutils';
import {buildNotesSum, buildSupNotesSum, getCofcLink} from 'api/customer/documents.api';
import {whatsapp} from 'assets/images';
import {EmailOutlined, NoteAdd, WhatsApp} from '@mui/icons-material';
import SendMessageDialog from 'ui-component/dialogs/sendMessage';
import {distinctByField} from "../../utils/sort";
import {docColumns} from "./profile/documents/Table";
import {GenerativeLoaderChip, GenerativeLoaderChipSum} from "../../ui-component/GenerativeLoader";
import FunctionsOutlinedIcon from "@mui/icons-material/FunctionsOutlined";

export const Transition = React.forwardRef(function Transition(
    props: TransitionProps & {
        children: React.ReactElement;
    },
    ref: React.Ref<unknown>,
) {
    return <Slide direction="up" ref={ref} {...props} />;
});

const headCells: HeadCell[] = [
        {
            id: 'actions',
            numeric: false,
            label: 'פעולות',
            align: 'center',
            filterable: false,
            sortable: false,
            pinned: 'left',
            cellRenderer: ({params, setCostumerDetails, setPreviewOpen, setCustomerAction, theme, dispatch}) => {
                const rowData = params?.data;
                const date = new Date();
                const whatsappCallBack = (result) => {
                    const client = JSDB().getClient(Number(rowData?.id))?.first
                    let text = JSDB().userCofcConf?.text && JSDB().userCofcConf?.text != "None" ? JSDB().userCofcConf?.text : `שלום ${"#name"} ${"#id"}אנא בצע הזמנה עבור ${JSDB().getUser().first.user_company_print_name}`
                    text = text.replace("#name", client.getName())
                    text = text.replace("#id", client.id)
                    text = text.replace("#external_id", client.getExternalId())
                    text = text.replace("#date", dateObjectToStringIsrael(date))
                    text = text.replace("#day", JSDB().getDatesManipulator().getDayOfWeekStr(dateObjectToString(date), true))

                    const url = `${text.replaceAll('\\\\n', '%0a')} %0a %0a https://nihulkav.shop?pass=${result}&date=${dateObjectToString(date)} %0a`;
                    // console.log(encodeURIComponent("🙏🏽"))
                    const phone = client.getPhone();
                    window?.open(`https://api.whatsapp.com/send?text=${url}&phone=${JSDB().getValidPhone(phone)}`, '_blank')?.focus();
                };
                if (params?.node?.group) return params?.value || '';
                return (
                    <>
                        <Box sx={{display: 'flex', flexDirection: 'row', justifyContent: 'center'}}>
                            <Tooltip title={<FormattedMessage id='customer-profile'/>}>
                                <IconButton onClick={() => {
                                    setCostumerDetails({
                                        name: params?.data?.name,
                                        id: params?.data?.id
                                    });
                                    setPreviewOpen(true)
                                }} size='small'>
                                    <IconId fontSize='small'/>
                                </IconButton>
                            </Tooltip>

                            <Tooltip title={<FormattedMessage id='edit'/>}>
                                <IconButton
                                    onClick={() => {
                                        setCustomerAction({editable: true, open: true, id: params?.data?.id})
                                        setCostumerDetails({
                                            name: params?.data?.name,
                                            id: params?.data?.id
                                        });
                                    }}
                                    size='small'>
                                    <IconEdit size={21} strokeWidth={2} color={theme?.palette?.primary?.main}/>
                                </IconButton>
                            </Tooltip>
                            <Tooltip title='שלח בווטסאפ לביצוע הזמנה'>
                                <IconButton size='small' onClick={(e) => {
                                    callbackNetworkResult(() => getCofcLink(Number(rowData.id), date), whatsappCallBack);

                                }}>
                                    <Avatar variant='square'
                                            sx={{cursor: 'pointer', bgcolor: 'white', width: '18px', height: '18px'}}
                                            src={whatsapp}/>
                                </IconButton>
                            </Tooltip>
                            <Tooltip title={params?.data?.active ? 'מחיקת לקוח' : 'להחזיר לקוח'}>
                                <IconButton
                                    size='small'
                                    onClick={() =>
                                        Swal.fire({
                                            title: params?.data?.active ? 'האם תרצה למחוק לקוח?' : 'האם תרצה להחזיר לקוח',
                                            text: '',
                                            icon: 'warning',
                                            showCancelButton: true,
                                            confirmButtonColor: theme?.palette?.primary?.dark,
                                            cancelButtonColor: theme?.palette?.error?.light,
                                            confirmButtonText: params?.data?.active ? 'מחק לקוח' : 'החזר לקוח',
                                            cancelButtonText: 'בטל'
                                        }).then((result) => {
                                            if (result?.isConfirmed) {
                                                dispatch(deleteCustomer({
                                                    id: Number(params?.data?.id),
                                                    active: !(params?.data?.active)
                                                }));
                                            }
                                        })
                                    }
                                >
                                    {params?.data?.active ?
                                        <IconTrash size={20} strokeWidth={2} color={theme?.palette?.error?.main}/> :
                                        <RestartAltIcon strokeWidth={2} color={"success"}/>}
                                </IconButton>
                            </Tooltip>
                        </Box>
                    </>
                )
            },
        },
        {
            id: 'id',
            numeric: false,
            label: 'מזהה',
            align: 'center'
        },
        {
            id: 'status',
            numeric: false,
            label: 'סטטוס',
            hide: true,
            enableRowGroup: true,
            align: 'center',
            cellRenderer: ({params}) => {
                return <CustomerStatus customer={params?.data}/>
            }
        },
        {
            id: 'name',
            numeric: false,
            label: 'שם לקוח',
            align: 'center',
            extendedSearchProperties: ['name', 'businessName'],
            searchQueryPlaceholder: 'שם/טלפון/מייל',
            cellRenderer({params, setCostumerDetails, setPreviewOpen}) {
                return (
                    <div style={{
                        display: 'flex',
                        flexDirection: 'column',
                        cursor: 'pointer',
                    }}
                         onClick={() => {
                             setCostumerDetails({
                                 name: params?.data?.name,
                                 id: params?.data?.id
                             });
                             setPreviewOpen(true)
                         }}
                    >
                        <Typography color={'blue'} variant='h5'>{params?.data?.name}</Typography>
                        {params?.data?.noTaxClient ? (
                            <MyChip label="ללא מע''מ" chipcolor='orange' size='small'/>
                        ) : undefined}
                    </div>
                )
            },
        },
        {
            id: 'businessName',
            numeric: false,
            label: 'פרטי עסק',
            align: 'center',
            extendedSearchProperties: ['businessName', 'businessId'],
            searchQueryPlaceholder: 'שם/ע.מ/ח.פ',
            enableRowGroup: true,
            editable: true,
            cellRenderer: ({params}) => {
                return (
                    <div style={{
                        display: 'flex',
                        flexDirection: 'column',
                    }}>
                        {(params?.data?.nkObject?.branch ?? -1) >= 0 ? <PeopleIcon
                            style={{color: params?.data?.nkObject?.master === -1 ? 'blue' : undefined}}/> : undefined}
                        <Typography variant='h5'>{params?.data?.businessName}</Typography>
                        {params?.data?.businessId ? (
                            <Typography variant='caption'>
                                {params?.data?.businessId} <br/>
                            </Typography>
                        ) : undefined}
                    </div>
                )
            }
        },
        {
            id: 'businessId',
            numeric: false,
            label: 'ח.פ/ע.מ',
            align: 'center',
            hide: false,
            extendedSearchProperties: ['businessName', 'businessId'],
            searchQueryPlaceholder: 'שם/ע.מ/ח.פ',
            enableRowGroup: true,
            editable: true,
        },
        {
            id: "position",
            label: "סדר",
            numeric: false,
            priority: -1,
            hide: true,
            editable: true,
        },
        {
            id: 'branch',
            numeric: false,
            label: 'רשת',
            hide: true,
            align: 'center',
            cellRenderer: ({params}) => {
                if (params?.node?.group) {
                    return params?.value || "";
                }
                const client = JSDB().getClient(Number(params?.data?.id))?.first;
                return (
                    <div style={{
                        display: 'flex',
                        flexDirection: 'column',
                    }}>
                        <Typography style={{
                            display: 'flex',
                            alignItems: 'center',
                            gap: '5px',
                        }} variant='h5'>
                            {(params?.data?.nkObject?.branch ?? -1) >= 0 ?
                                <PeopleIcon style={{
                                    color: params?.data?.nkObject?.master === -1 ? 'blue' :
                                        undefined
                                }}
                                /> :
                                undefined}
                            {client?.getBusinessName()}
                        </Typography>
                        <Typography
                            variant='h5'>{(params?.data?.nkObject?.branch ?? -1) >= 0 ? params?.data?.nkObject?.master === -1 ? "סניף מרכזי" : "רשת" : 'פרטי'}
                        </Typography>
                    </div>
                )
            },
            extractFunc: (data) => {
                const client = JSDB().getClient(Number(data?.id))?.first;
                const branch = client?.branch
                if (branch && branch != -1) {
                    return client?.getBusinessName()
                }
                return 'פרטי'
            }
        },
        {
            id: "category",
            label: "קטגורית לקוחות",
            numeric: false,
            priority: -1,
            hide: true,
            editable: true,
        },
        {
            id: "group",
            label: "קבוצת לקוחות",
            numeric: false,
            priority: -1,
            hide: true,
            editable: true,
        },
        {
            id: "comments",
            label: "הערות",
            numeric: false,
            priority: -1,
            hide: true,
            cellEditor: 'agLargeTextCellEditor',
            editable: true,
        },


        {
            id: "days_to_pay",
            label: "שוטף לתשלום",
            numeric: true,
            editable: true,
            priority: -1,
            hide: true,
        },

        {
            id: "email",
            label: "מייל",
            numeric: false,
            priority: -1,
            hide: true,
            editable: true,
            cellRenderer: ({params}) => {
                return (
                    <div style={{
                        display: 'flex',
                        flexDirection: 'column',
                        cursor: 'pointer',
                    }}
                         onClick={() => window.open(`mailto:${params?.data?.email}`, '_blank')?.focus()}
                    >
                        {params?.data?.email && <Typography variant="subtitle1">
                            {' '}
                            <Mail size={14} strokeWidth={1} color="#000000"/> {params?.data?.email}
                        </Typography>}
                    </div>
                )
            }
        },

        {
            id: 'contactName',
            numeric: false,
            label: 'איש קשר',
            align: 'center',
            hide: true,
            editable: true,
        },
        {
            id: 'contactName2',
            numeric: false,
            label: 'איש קשר 2',
            align: 'center',
            hide: true,
            editable: true,
        },
        {
            id: 'includeTax',
            numeric: false,
            label: 'כולל מעמ',
            align: 'center',
            hide: true,
            extractFunc: (row) => {
                return row?.includeTax ? 'כן' : 'לא'
            },
            editable: true,
        },
    {
            id: 'priceControl',
            numeric: false,
            label: 'מחירון',
            align: 'center',
            hide: true,
        cellRenderer: ({params,priceMenus}) => {

            const menu = priceMenus.find((s) => s.id == Number(params?.data?.priceControl))
            if(!menu) return ''
            return <div> {menu?.name} </div>


        },
            editable: false,
        },
        {
            id: 'agent',
            numeric: false,
            label: 'שם קו',
            align: 'center',
            searchQueryPlaceholder: 'שם קו/קטגוריה',
            extendedSearchProperties: ['agent', 'category'],
            enableRowGroup: true,
            editable: true,
            // cellRenderer: ({ params, column, menuItems }) => {
            //     !menuItems?.[column?.id]?.includes(params?.data?.agent) && menuItems?.[column?.id]?.push(params?.data?.agent)
            //     return (
            //         <div style={{
            //             display: 'flex',
            //             flexDirection: 'column',
            //         }}>
            //             <Typography variant='h5'>{params?.data?.agent}</Typography>
            //             {(params?.data?.category != '') ? <Typography variant="caption">
            //                 {params?.data?.category}
            //             </Typography> : undefined}
            //         </div>
            //     )
            // }
        },
        {
            id: 'driver',
            numeric: false,
            label: 'נהג',
            align: 'center',
            // aggFunc:(params) => {
            //     return params.values[0]
            // },
            searchQueryPlaceholder: 'נהג/מלקט',
            extendedSearchProperties: ['driver', 'collector'],
            enableRowGroup: true,
            editable: true,
            // cellRenderer: ({ params }) => {
            //     return (
            //         <div style={{
            //             display: 'flex',
            //             flexDirection: 'column',
            //         }}>
            //             {(params?.data?.driver) ? <Typography variant='h5'>{params?.data?.driver}</Typography> : undefined}
            //             {(params?.data?.collector) ? <Typography variant='h5'>{params?.data?.collector}</Typography> : undefined}
            //         </div>
            //     )
            // }
        },
        {
            id: 'collector',
            numeric: false,
            label: 'מלקט',
            hide: true,
            align: 'center',
            // cellRenderer: ({ params }) => {
            //     return (
            //         <div style={{
            //             display: 'flex',
            //             flexDirection: 'column',
            //         }}>
            //             {(params?.data?.driver) ? <Typography variant='h5'>{params?.data?.driver}</Typography> : undefined}
            //             {(params?.data?.collector) ? <Typography variant='h5'>{params?.data?.collector}</Typography> : undefined}
            //         </div>
            //     )
            // }
        },

        {
            id: 'city',
            numeric: false,
            label: 'כתובת',
            align: 'center',
            extendedSearchProperties: ['city', 'street', 'streetNumber'],
            searchQueryPlaceholder: 'עיר/כתובת',
            enableRowGroup: true,
            editable: true,
            extractFunc: (row) => {
                return row?.city
            },
            cellRenderer: ({params}) => {

                const address = params?.data?.street && params?.data?.streetNumber ? `${params?.data?.street}, ${params?.data?.streetNumber}` : params?.data?.street;
                const full_address = params?.data?.city && address ? `${address} ${params?.data?.city}` : params?.data?.city;
                const openGmaps = (result) => {
                    window?.open(`https://www.google.com/maps/search/?api=1&query=${result}`, '_blank')?.focus();
                };
                if (params?.node?.group) return params?.value || "";
                return (
                    <div style={{
                        display: 'flex',
                        flexDirection: 'column',
                        cursor: 'pointer',
                    }}
                         onClick={() => openGmaps(params?.data?.location != "" ? params?.data?.location : full_address)}
                    >
                        <>
                            {!params?.data?.city && !address && params?.data?.location == '' ? <div></div> : (
                                <>
                                    <Typography variant="subtitle1">
                                        {' '}
                                        <MapPin size={14} strokeWidth={1} color="#000000"/> {params?.data?.city}
                                    </Typography> <Typography variant="caption">{address}</Typography>
                                </>
                            )}
                        </>
                    </div>
                )
            }
        },
        {
            id: 'street',
            numeric: false,
            label: 'רחוב',
            align: 'center',
            hide: true,
            extractFunc: (row) => {
                return `${row?.street} ${row?.streetNumber}`
            },

        },
    {
        id: 'city',
        numeric: false,
        label: 'עיר',
        align: 'center',
        editable:true,
        hide: true,
        extractFunc: (row) => {
            return `${row?.city}`
        },

    },
        {
            id: "location",
            label: "מיקום",
            hide: true,
            numeric: false,
            editable:true,
            priority: -1,
            cellRenderer: ({params}) => {
                const openGmaps = (result) => {
                    window.open(`https://www.google.com/maps/search/?api=1&query=${result}`, '_blank')?.focus();
                };
                return (
                    <div style={{
                        display: 'flex',
                        cursor: 'pointer',
                        alignItems: "center",
                    }}
                         onClick={() => openGmaps(params?.data?.location)}
                    >
                        {params?.data?.location != "" && <><MapPin size={14} strokeWidth={1} color="#000000"/>
                            <div><Typography variant="caption">{params?.data?.location}</Typography></div>
                        </>}
                    </div>
                )
            }
        },
        {
            id: 'externalId',
            numeric: false,
            label: 'מזהה חיצוני',
            align: 'center',
            type:'numOrStr',
            extendedSearchProperties: ['externalId'],
            searchQueryPlaceholder: 'מזהה חיצוני',
            editable: true,
        },
        {
            id: 'date',
            type: 'date_range',
            numeric: false,
            hide: true,
            hideForce: true,
            label: 'תאריך יצירה',
            align: 'center',
            searchQueryPlaceholder: 'תאריך',
            priority: -1,
            disableCheckbox: true,
        },
        {
            id: 'obligo',
            numeric: false,
            label: 'אובליגו',
            align: 'center',
            hide: true,
            editable: true,
        },
    {
        id: 'min_order',
        numeric: true,
        label: 'מינמום הזמנה',
        align: 'center',
        hide: true,
        editable: true,
    },    {
        id: 'min_order_sum',
        numeric: true,
        label: 'מינמום הזמנה בכסף',
        align: 'center',
        hide: true,
        editable: true,
    },
        {
            id: 'discount',
            numeric: false,
            label: 'הנחה',
            align: 'center',
            hide: true,
            editable: true,
        },
        {
            id: 'net_edi',
            label: 'EDI Net',
            numeric: false,
            hide: true,
            editable: (params) => true,
        },
        {
            id: 'branch_number',
            label: 'EDI Branch',
            numeric: false,
            hide: true,
            editable: (params) => true,

        },
        {
            id: 'edi_client_id',
            label: 'EDI Number',
            numeric: false,
            hide: true,
            editable: (params) => true,
        },
        {
            id: 'internal_sup_edi',
            label: 'net Sup ID',
            numeric: false,
            hide: true,
            editable: (params) => true,
        },
        {
            id: 'edi_sup_id',
            label: 'edi Sup ID',
            numeric: false,
            hide: true,
            editable: (params) => true,
        },
        {
            id: 'action_time',
            numeric: false,
            label: 'עדכון אחרון',
            hide: true,
            align: 'center',
            editable: (params) => true,
        },

        {
            id: 'sunday',
            numeric: false,
            label: 'יום א',
            hide: true,
            align: 'center',
            editable: (params) => false,
            extractFunc: (row) => {
                return row?.sunday ? 'כן' : 'לא'
            }
        },
        {
            id: 'monday',
            numeric: false,
            label: 'יום ב',
            hide: true,
            align: 'center',
            editable: (params) => false,
            extractFunc: (row) => {
                return row?.monday ? 'כן' : 'לא'
            }
        },
        {
            id: 'tuesday',
            numeric: false,
            label: 'יום ג',
            hide: true,
            align: 'center',
            editable: (params) => false,
            extractFunc: (row) => {
                return row?.tuesday ? 'כן' : 'לא'
            }
        },
        {
            id: 'wednesday',
            numeric: false,
            label: 'יום ד',
            hide: true,
            align: 'center',
            editable: (params) => false,
            extractFunc: (row) => {
                return row?.wednesday ? 'כן' : 'לא'
            }
        },
        {
            id: 'thursday',
            numeric: false,
            label: 'יום ה',
            hide: true,
            align: 'center',
            editable: (params) => false,
            extractFunc: (row) => {
                return row?.thursday ? 'כן' : 'לא'
            }
        },
        {
            id: 'friday',
            numeric: false,
            label: 'יום ו',
            hide: true,
            align: 'center',
            editable: (params) => false,
            extractFunc: (row) => {
                return row?.friday ? 'כן' : 'לא'
            }
        },
    ]
;


const CustomerList = () => {
    const theme = useTheme();
    const dispatch = useDispatch();
    const agent: Agent[] = useSelector(selectLines);
    const drivers = useSelector(selectDrivers);

    const asyncState = useSelector(selectGetCustomersAsyncState);
    const [openLoader, setOpenBack] = React.useState<boolean>(false);
    const [state, setColumnState] = React.useState(undefined);
    const [displayActive, setDisplayActive] = React.useState<boolean>(true);
    const customers = useSelector(state => selectCustomersObjs(state, displayActive));
    const [cols, setCols] = React.useState<HeadCell[]>(headCells);
    const priceMenus = useSelector(state => selectPriceMenus(state));
    React.useEffect(() => {
        const newCols = cloneDeep((cols))

        const phone1 = JSDB().getUser().first?.getJsonDesc("cphone1")
        let labelPhone1 = "טלפון";
        if (phone1) {
            labelPhone1 = phone1.name;
        }
        newCols.push(   {
            id: "phone",
            label: labelPhone1,
            numeric: false,
            editable: true,
            priority: -1,
            cellRenderer: ({params}) => {
                const openPhone = (result) => {
                    window.open(`tel:${result}`, '_blank')?.focus();
                };
                return (
                    <div style={{
                        display: 'flex',
                        flexDirection: 'column',
                    }}
                    >
                        {params?.data?.phone && <Typography variant="subtitle1" style={{cursor: "pointer"}}
                                                            onClick={() => openPhone(params?.data?.phone)}>
                            <IconPhone size={12} strokeWidth={1} color='#000000'/> {params?.data?.phone}
                        </Typography>}
                    </div>
                )
            }
        }  )

        const phone2 = JSDB().getUser().first?.getJsonDesc("cphone2")
        let labelPhone2 = "טלפון 2";
        if (phone2) {
            labelPhone2 = phone2.name;
        }
        newCols.push( {
            id: "phone2",
            label: labelPhone2,
            numeric: false,
            hide: true,
            priority: -1,
            editable: true,
            cellRenderer: ({params}) => {
                const openPhone = (result) => {
                    window.open(`tel:${result}`, '_blank')?.focus();
                };
                return (
                    <div style={{
                        display: 'flex',
                        flexDirection: 'column',
                    }}
                    >
                        {params?.data?.phone2 && <Typography variant="subtitle1" style={{cursor: "pointer"}}
                                                             onClick={() => openPhone(params?.data?.phone2)}>
                            <IconPhone size={12} strokeWidth={1} color='#000000'/> {params?.data?.phone2}
                        </Typography>}
                    </div>
                )
            }
        })
        const phone3 = JSDB().getUser().first?.getJsonDesc("cphone3")
        let labelPhone3 = "טלפון 3";
        if (phone3) {
            labelPhone3 = phone3.name;

        }
        newCols.push({
            id: "phone3",
            label: labelPhone3,
            numeric: false,
            hide: true,
            priority: -1,
            editable: true,
            cellRenderer: ({params}) => {
                const openPhone = (result) => {
                    window.open(`tel:${result}`, '_blank')?.focus();
                };
                return (
                    <div style={{
                        display: 'flex',
                        flexDirection: 'column',
                    }}
                    >
                        {params?.data?.phone3 && <Typography variant="subtitle1" style={{cursor: "pointer"}}
                                                             onClick={() => openPhone(params?.data?.phone3)}>
                            <IconPhone size={12} strokeWidth={1} color='#000000'/> {params?.data?.phone3}
                        </Typography>}
                    </div>
                )
            }
        } )
            const notes2 = JSDB().getUser().first?.getJsonDesc("cnotes2")
            let labelNotes2 = "הערות לקוח 2";
            if (notes2) {
                labelNotes2 = notes2.name;


            }
            newCols.push(    {
                id: "notes2",
                label: labelNotes2,
                numeric: false,
                editable: true,
                type:'numOrStr',
                hide: true,
                priority: -1,

            })


            const notes3 = JSDB().getUser().first?.getJsonDesc("cnotes3")
            let labelNotes3 = "הערות לקוח 3";
            if (notes3) {
                labelNotes3 = notes3.name;


            }
            newCols.push(    {
                id: "notes3",
                label: labelNotes3,
                numeric: false,
                hide: true,
                type:'numOrStr',
                editable: true,
                priority: -1,
            })
            const notes4 = JSDB().getUser().first?.getJsonDesc("cnotes4")
            let labelNotes4 = "הערות לקוח 4";
            if (notes4) {
                labelNotes4 = notes4.name;


            }
        newCols.push(    {
            id: "notes4",
            label: labelNotes4,
            numeric: false,
            type:'numOrStr',
            editable: true,
            hide: true,
            priority: -1,

        })

        const paymentNotes = JSDB().getUser().first?.getJsonDesc("payment_notes")
        let paymentNotesLabel = "תנאי תשלום";
        if (paymentNotes) {
            paymentNotesLabel = paymentNotes.name;


        }
        newCols.push(    {
            id: "paymentComments",
            label: paymentNotesLabel,
            numeric: false,
            type:'numOrStr',
            editable: true,
            hide: true,
            priority: -1,

        })
            setCols(newCols)


    }, []);
    const handleNotActiveUsers = React.useCallback(() => {
        if (displayActive)
            setDisplayActive(false)
        else setDisplayActive(true)

    }, [displayActive]);


    const f = async (file) => {
        setOpenBack(true)
        try {
            return load_from_xlsx(file, "CLIENTS",);
        } catch (e) {

        }
    }
    React.useEffect(() => {
        dispatch(getCustomers());
        dispatch(getAgents());
    }, [dispatch]);

    const handleDrawerClose = React.useCallback(() => {
        setCustomerAction({
            editable: false,
            open: false,
            id: ''
        });
    }, []);

    // Add/Edit Customer drawer
    const [customerAction, setCustomerAction] = React.useState({
        editable: false,
        open: false,
        id: ''
    });

    const [new_clients, set_new_clients] = React.useState<boolean>(false);
    React.useEffect(() => {
        if (new_clients) {
            dispatch(getCustomersCreation())

        } else {
            dispatch(removeAllFilters())
        }
    }, [new_clients]);


    React.useEffect(() => {

        dispatch(removeAllFilters())
    }, [dispatch]);
    const [menuItems, setMenuItems] = React.useState<{
        agent?: {
            label: string,
            value: string
        }[],
        driver?: {
            label: string,
            value: string
        }[],
    }>({
        agent: [],
        driver: [],

    })

    React.useEffect(() => {
        if (menuItems?.agent?.length == 0 || menuItems?.driver?.length == 0)
            setMenuItems({
                driver: drivers.map((s) => {
                    return {
                        label: s.name,
                        value: s.name
                    }
                }),
                agent: agent.map((s) => {
                    return {
                        label: s.name,
                        value: s.name
                    }
                })
            })
    }, [agent, drivers]);

    const [previewOpen, setPreviewOpen] = React.useState(false);
    const [costumerDetails, setCostumerDetails] = React.useState({
        name: '',
        id: '',
    })
    const [openSendMessage, setOpenSendMessage] = React.useState(false);
    const [chooseBranch, setChooseBranch] = React.useState(false);
    const [branches, setBranches] = React.useState<string[]>([]);

    React.useEffect(() => {
        const branchesSet: any = [];
        customers.forEach((c) => {
            if (c?.nkObject?.branch >= 0) {
                if (branchesSet.find((s) => s.name == c.businessName && s.branch == c.nkObject.branch)) {
                    return;
                }
                let master = c.nkObject.master
                if (!master || master == -1)
                    master = c.id
                branchesSet.push({
                    name: c?.businessName,
                    id: c?.businessId,
                    branch: c.nkObject.branch,
                    master: master,
                });
            }
        });
        setBranches(branchesSet);
    }, [customers]);

    const chipDetails = React.useMemo(() => {
        return [
            {
                action: (params, originalTableState) => {
                    const isGrouped = customGroupActiveForChip(params, ["branch"])
                    const allColumns = params.columnApi.getColumns();
                    const colsToDislay = [
                        "id",
                        "branch",
                        "name",
                        'edi_sup_id',
                        'edi_client_id',
                        'net_edi',
                        'branch_number',
                        'internal_sup_edi',
                    ];
                    const colsToHide = [
                        'edi_sup_id',
                        'edi_client_id',
                        'net_edi',
                        'branch_number',
                        'internal_sup_edi',
                    ]
                    if (!isGrouped) {
                        setColumnState(params?.columnApi?.getColumnState())
                        params?.columnApi?.applyColumnState({
                            state: allColumns.map((col) => {
                                if (col.colDef.id === "branch") {
                                    return {colId: col.colId, hide: false, rowGroup: true}
                                }
                                if (colsToDislay.includes(col.colDef.id)) {
                                    return {colId: col.colId, hide: false}
                                } else {
                                    return {colId: col.colId, hide: true}
                                }
                            }),
                            applyOrder: true,
                        });
                    } else {

                        params?.columnApi?.applyColumnState({
                            state: state ?? allColumns.map((col) => {
                                if (col.colDef.id === "branch") {
                                    return {colId: col.colId, hide: false, rowGroup: false}
                                }
                                if (colsToHide.includes(col.colDef.id) || col?.colDef?.hide) {
                                    return {colId: col.colId, hide: true}
                                } else {
                                    return {colId: col.colId, hide: false}
                                }
                            }),
                            applyOrder: true,
                        });
                    }
                },
                label: (params) => customGroupLabelForChip(params, ["branch"], "קבץ לפי רשתות", "הסר קיבוץ לפי רשתות"),
                icon: <PeopleIcon/>,
                tooltip: "קבץ את העמודות לפי רשתות",
                active: (params) => customGroupActiveForChip(params, ["branch"]),
            },
            {
                action: (params, originalTableState) => customGroup(params, ["agent"]),
                label: (params) => customGroupLabelForChip(params, ["agent"], "קבץ לפי קווים", "הסר קיבוץ לפי קווים"),
                icon: <IconInfoCircle/>,
                tooltip: "קבץ את העמודות לפי קווים ",
                active: (params) => customGroupActiveForChip(params, ["agent"]),
            },
            {
                action: (params, originalTableState) => customGroup(params, ["driver"]),
                label: (params) => customGroupLabelForChip(params, ["driver"], "קבץ לפי נהגים", "הסר קיבוץ לפי נהגים"),
                icon: <IconInfoCircle/>,
                tooltip: "קבץ את העמודות לפי נהגים ",
                active: (params) => customGroupActiveForChip(params, ["driver"]),
            },
            {
                action: (params, originalTableState) => customGroup(params, ["city"]),
                label: (params) => customGroupLabelForChip(params, ["city"], "קבץ לפי ערים", "הסר קיבוץ לפי ערים"),
                icon: <IconInfoCircle/>,
                tooltip: "קבץ את העמודות לפי ערים ",
                active: (params) => customGroupActiveForChip(params, ["city"]),
            },
            // {
            //     icon: <CloudUploadIcon />,
            //     tooltip: "העלה קובץ",
            //     action: () => {
            //         const button = document.getElementById("upload-file-dialog-button");
            //         if (button) {
            //             button.click();
            //         }
            //     },
            //     label: () => {
            //         return "העלה קובץ"
            //     },
            //     active: () => false
            // },
            {
                action: (params) => {
                    const allCols = params?.columnApi?.getColumns();
                    const createDateCol = allCols?.find((col) => col?.colDef?.id === "date");
                    if (createDateCol?.visible) {
                        set_new_clients(false)
                        params?.columnApi?.setColumnVisible(createDateCol?.colId, false)
                    } else {
                        set_new_clients(true)
                        params?.columnApi?.setColumnVisible(createDateCol?.colId, true)
                    }
                },
                label: (params) => params?.columnApi?.getColumns()?.find((col) => col?.colDef?.id === "date")?.visible ? "הסתר תאריך יצירה" : "הצג תאריך יצירה",
                icon: <IconInfoCircle/>,
                tooltip: "הצג תאריך יצירה",
                active: (params) => params?.columnApi?.getColumns()?.find((col) => col?.colDef?.id === "date")?.visible
            },
        ]
    }, []);

    const selectionChips = React.useCallback((selectedItems) => {
        return (
            <>
                <Tooltip title='שלח מחירונים באימייל' onClick={(a) => {
                    // callback
                }}
                >
                    <Chip
                        icon={<EmailOutlined/>} sx={{
                        ml: 0.5, mr: 0.5, '& .MuiChip-icon': {
                            color: '#000080',
                        },
                    }} label={"שלח מחירונים באימייל"} variant="outlined"/>
                </Tooltip>
                <Tooltip title='שלח מחירונים בוואטסאפ' onClick={(a) => {
                    // callback
                }}
                >
                    <Chip
                        icon={<WhatsApp/>} sx={{
                        ml: 0.5, mr: 0.5, '& .MuiChip-icon': {
                            color: '#000080',
                        },
                    }} label={"שלח מחירונים בוואטסאפ"} variant="outlined"/>
                </Tooltip>
            </>
        )
    }, []);

    const saveDataCallback = React.useCallback(async (data) => {
        try {
            const x = await changeAllCustomers(data)
            dispatch(fillCustomers());

            return true
        } catch (e) {
            console.log(e)
            return false
        }

    }, [changeAllCustomers, dispatch])

    const callCustomerFetch = React.useCallback(async (setLoader) => {
        try {
            fetchMimshakClient(new Date(),()=>{
                dispatch(getCustomers());
            })


            return true
        } catch (e) {
            console.log(e)
            return false
        }
        setLoader(false)

    }, [changeAllCustomers, dispatch])

    const addDataAction = React.useMemo(() => {
        return {
            tooltip: "הוסף לקוח",
            label: "הוסף לקוח",
            action: () => {
                setCustomerAction({editable: false, open: true, id: ''})
            }
        }
    }, [customerAction])

    const rightClickActions = React.useMemo(() => {
        return {
            branch: {
                name: "בחר/ צור רשת",
                action: () => {
                    setChooseBranch(true);
                },
            },
            name: {
                name: "בחר/ צור רשת",
                action: () => {
                    setChooseBranch(true);
                },
            }, businessName: {
                name: "בחר/ צור רשת",
                action: () => {
                    setChooseBranch(true);
                },
            }
        }
    }, [chooseBranch]);

    const specialDialogs = React.useCallback((params) => {
        return <CreateBranchDialog
            chooseBranch={chooseBranch}
            setChooseBranch={setChooseBranch}
            branches={branches}
            params={params}
        />
    }, [chooseBranch, branches])

    const [selectedCustomers, setSelectedCustomers] = React.useState<any[]>([])
    React.useEffect(() => {
        if (!openSendMessage) {
            setSelectedCustomers([])
        }
    }, [openSendMessage])
    const contextMenu = React.useCallback((params) => {

        const ranges = params?.api?.getCellRanges();

        const selected = getSelectedRows(params?.api)
        let nodesInRange: any = [];
        selected?.forEach((row) => {
            nodesInRange.push(row);
        })
        if (!selected?.length) {
            ranges?.forEach((range) => {
                const rowMin = range?.startRow?.rowIndex < range?.endRow?.rowIndex ? range?.startRow?.rowIndex : range?.endRow?.rowIndex;
                const rowMax = range?.startRow?.rowIndex > range?.endRow?.rowIndex ? range?.startRow?.rowIndex : range?.endRow?.rowIndex;
                for (let i = rowMin; i <= rowMax; i += 1) {
                    const node = params.api?.getDisplayedRowAtIndex(i);
                    if (node?.data) {
                        nodesInRange.push(node?.data)
                    }
                }
            })
        }
        nodesInRange = distinctByField(nodesInRange, "id")
        setSelectedCustomers(nodesInRange.map((item: any) => item?.id))

        return [
            {
                name: "שלח הודעה",
                action: () => {
                    setOpenSendMessage(true)
                }
            }
        ]
    }, [chooseBranch])

    const tableControls: ITableControls = {
        tableName: 'customers/customers-list',
        sidebar: true,
        localisation: true,
        groupBy: {
            enable: true,
            rowGroup: true,
            pivot: true,
            value: true,
        },
        selection: {
            enable: true,
            mode: "multiple"
        },
        extractId: (row) => row?.data?.id,
        cellRenderer: (params, column, i) => {

            if (column.cellRenderer) {
                return column.cellRenderer({
                    params,
                    column,
                    i,
                    setCostumerDetails,
                    setPreviewOpen,
                    setCustomerAction,
                    theme,
                    dispatch,
                    menuItems,
                    priceMenus
                })
            }


            if (column.label === "תאריך יצירה" || column.id === "date") {
                return (
                    <div style={{
                        display: 'flex',
                        flexDirection: 'column',
                    }}
                    >
                        {params?.data?.date && <Typography variant="subtitle1">
                            {dayjs(params?.data?.date)?.format('DD/MM/YYYY')}
                        </Typography>}
                    </div>
                )
            }
            if (typeof params?.value === "object") {
                const value = params?.value?.value || "";
                return value;
            }
            return params?.value || '';
        },
        sideBarActions: [
            {
                buttonLabel: "הצג פרטי לקוח",
                displayList: [
                    'שם לקוח',
                    'פרטי עסק',
                    'טלפון',
                    'טלפון 2',
                    'מייל'
                ]
            }
        ],
    }

    return (
        <>
            {asyncState === 'loading' && <Loader/>}

            <AgTableContainer controls={() => {
                return (
                    <Box style={{
                        width: "100%",
                        display: "flex",
                        justifyContent: "start",
                        position: "relative",
                        height: "100%",
                        alignItems: "center"
                    }}>
                        <div style={{
                            position: "absolute",
                            left: "0",
                            top: "0",
                            height: "100%",
                            display: "flex",
                            alignItems: "center",
                        }}>
                            <Chip
                                label={displayActive ? "הצג לקוחות לא פעילים" : "הצג לקוחות פעילים"}
                                icon={<User style={{
                                    color: displayActive ? "red" : "blue",
                                }}/>}
                                onClick={() => {
                                    handleNotActiveUsers()
                                }}
                                variant="outlined"
                                style={{
                                    color: displayActive ? "red" : "blue",
                                    borderColor: displayActive ? "red" : "blue",
                                }}
                            />
                            <Tooltip title={'משיכת לקוחות מממשק'}>

                                <GenerativeLoaderChip labelText={'משיכת לקוחות מממשק'} func={(setLoader) => {
                                    callCustomerFetch(setLoader)


                                }} icon={<PeopleIcon sx={{width: '24px', height: '24px', mr: 0, ml: 0, p: 0}}/>}/>

                            </Tooltip>

                        </div>
                    </Box>
                )
            }} tableName={
                <div>
                    <span>
                        רשימת לקוחות {" "}
                    </span>
                    <span style={{
                        color: displayActive ? "blue" : "red",
                    }}>
                        {
                            displayActive ? "פעילים" : "לא פעילים"
                        }
                    </span>
                </div>
            }>
                <AgTable
                    columns={cols}
                    data={customers}
                    tableControls={tableControls}
                    chipDetails={chipDetails}
                    saveDataCallback={saveDataCallback}
                    menuiItems={menuItems}
                    addDataAction={addDataAction}
                    rightClickActions={rightClickActions}
                    specialDialogs={specialDialogs}
                    selectionControls={selectionChips}
                    extentedContextMenu={contextMenu}
                />
            </AgTableContainer>
            {customerAction.open ? <AddCustomer
                open={customerAction.open}
                handleDrawerOpen={handleDrawerClose}
                id={customerAction.id}
                editable={customerAction.editable}
                setPreviewOpen={setPreviewOpen}
                branches={branches}
            /> : undefined}
            {previewOpen ? <Dialog TransitionComponent={Transition} fullScreen fullWidth open={previewOpen}
                                   onBackdropClick={() => {
                                       setPreviewOpen(false)
                                       setCostumerDetails({
                                           id: "",
                                           name: ""
                                       })
                                   }}
                                   sx={{'& .MuiDialog-paper': {p: 0}}}>
                <AppBar sx={{position: 'sticky'}}>
                    <Toolbar>
                        <IconButton
                            edge="start"
                            color="inherit"
                            onClick={() => {
                                setPreviewOpen(false)
                                setCostumerDetails({
                                    id: "",
                                    name: ""
                                })
                            }}
                            aria-label="close"
                        >
                            <CloseIcon/>
                        </IconButton>
                        <Typography sx={{ml: 2, flex: 1, color: 'white'}} variant="h6" component="div">
                            סגור חלון
                        </Typography>
                        <Typography onClick={() => {
                            setPreviewOpen(false)
                            setCostumerDetails({
                                id: "",
                                name: ""
                            })
                        }}>
                            {costumerDetails?.name}
                        </Typography>
                    </Toolbar>
                </AppBar>
                <CustomerPreview id={costumerDetails?.id}/>
            </Dialog> : undefined}
            <div style={{
                display: "none"
            }}>
                <DialogUploadFile
                    uploadCallback={() => {
                        // CALLBACK
                    }}
                />
            </div>
            {openSendMessage ? <SendMessageDialog
                open={openSendMessage}
                setOpen={setOpenSendMessage}

                toSelected={selectedCustomers}
                withFiles={false}
            /> : undefined}

        </>
    );
};

export default CustomerList;

import JSDB from '../fromKotlin/nk';
import {badPhoto, emptyPhoto, byteArrayToImg, dateObjectToString, stringToTime} from '../fromKotlin/nkutils';
import {toArrayOrEmpty} from "./DocumentsTypes/utils";

interface Product {
    id: number; // dont show
    name: string; // name of the agent;
    barcode: string; //
    default_price: number;
    position: number;
    active: boolean;
    isClientProduct: boolean;

    isNoTax: boolean;
    taklit_id: number;
    taklit_type: number;
    external_id: string;
    unit: string;
    unit_amount: number;
    cost_price: number;
    cost_price_updated?: number;
    category: string;
    category2: string;
    base_price: number;
    return_cost: boolean;
    connected_products: Product[];
    min_amount?: number;
    forceLock?: boolean;
    hourLock?: Date;
    dayLock?: number[];
    sale?: boolean;
    unit_preferece?: number
    step?: number;

    collector?: number;
    // filling later

    image?: any;
    extra_unit?: string | 0;
    conversion_rate?: number;
    split_category?: number;
    available?: boolean;
    weight?: number;
    volume?: number;
    englishName?: string;
    orderName?: string;
    orderPosition?: number;
    nkObject: any;
}

export const getImgFromDB = (p: any): string => {
    let img = emptyPhoto;
    const temp_img = JSDB().getProductImage(Number(p.id)).first;
    if (temp_img === null || byteArrayToImg(temp_img) === (badPhoto) || temp_img === '')
        img = undefined;
    else
        img = byteArrayToImg(temp_img);
    return img;
};
export const updateImg = (p: Product) => {
    p.image = getImgFromDB(p);
};
export const deserializeDayLock = (dayLock: any): number => {
    let s = 0
    dayLock.forEach((d: any) => {
        s += Math.pow(2, d-1)
    })
    return s
}
export const deserializeProduct = (p: any): Product => {
    const o = p.getOrderProduct();
    const cp = p.getConnectedProduct()
    const cost_price_updated = JSDB().getProductCost(p.id)?.get(dateObjectToString(new Date()))?.first

    return {
        active: p.getActive(),
        barcode: p.getBarcode(),
        connected_products: (cp.size > 0) ? cp.toArray() : [],
        default_price: p.getDefaultPrice(),
        external_id: p.getExternalId(),
        id: p.id,
        isClientProduct: p.isClient,
        isNoTax: p.getNoTaxProduct() === 1,
        name: p.getName(),
        position: p.getPosition(),
        taklit_id: p.taklit_id,
        taklit_type: p.taklit_type,
        unit: p.getUnitName(),
        unit_amount: p.getUnitAmmount(),
        category: p.getCategory(),
        cost_price: p.getCostPrice(),
        base_price: p.getBasePrice(),
        split_category: p.split_category,
        // return_cost:p.getCostIncludeReturns()==1,
        return_cost: p.cost_include_returns == 1,
        extra_unit: o?.getExtraUnitName() ?? null,
        conversion_rate: o?.conversion_rate ?? null,
        available: o?.available === 1 ?? null,
        weight: o?.weight ?? null,
        volume: o?.volume ?? null,
        englishName: o?.englishName ?? null,
        orderName: o?.orderName ?? null,
        orderPosition: o?.position ?? null,
        nkObject: p,
        category2: p.category2,
        step: o?.step ?? 0,
        min_amount: o?.min_order ?? 0,
        sale: o?.sales == 1,
        collector: o?.collector ?? -1,
        forceLock: o?.forceLock == 1,
        cost_price_updated: cost_price_updated ?? 0,

        hourLock: o?.hourLock ? stringToTime(o?.hourLock): stringToTime('00:00'),
        dayLock: o ? toArrayOrEmpty(o?.daysInt()) : [],
        unit_preferece: o?.unit_preferece ?? 0,
        image: getImgFromDB(p)
    };
};

export const makeProduct = ({
                                id = -1,
                                name = '',
                                barcode = '',
                                default_price = 0,
                                position = 0,
                                active = true,
                                isClientProduct = false,

                                isNoTax = false,
                                taklit_id = -1,
                                taklit_type = -1,
                                external_id = "",
                                unit = '',
                                unit_amount = 0,
                                category = '',
                                cost_price = 0,
                                cost_price_updated = 0,
                                base_price = 0,
                                return_cost = true,
                                connected_products = [],
                                category2 = '',
                                image = emptyPhoto,
                                extra_unit = '',
                                conversion_rate = 0,
                                available = true,
                                weight = 0,
                                volume = 0,
                                englishName = '',
                                step = 0,
                                forceLock = false,
                                hourLock = '',
                                dayLock = [1, 2, 3, 4, 5, 6, 7],
                                sale = false,
                                unit_preferece = 0,
                                default_unit = 0,
                                collector = -1,
                            min_amount = 0,
                                split_category = 0,
                                nkObject = null
                            }) => ({
    id,
    name,
    barcode,
    default_price,
    position,
    active,
    isClientProduct,
    return_cost,
    isNoTax,
    taklit_id,
    taklit_type,
    external_id,
    category2,
    unit,
    unit_amount,
    category,
    cost_price,
    cost_price_updated,
    connected_products,
    base_price,
    image,
    extra_unit,
    conversion_rate,
    available,
    weight,
    volume,
    englishName,
    step,
    forceLock,
    hourLock,
    dayLock,
    sale,
    unit_preferece,
    default_unit,
    collector,
    min_amount,
    split_category,
    nkObject
});

export default Product;

import Customer from './customer';
import JSDB, {NKTypeConvertor} from '../fromKotlin/nk';
import {ProductNote} from './DocumentsTypes/Document';
import {ICustomer} from '../cdmanagment/src/types/customerStore-types';
import {roundDecimals, StringToIsrael} from '../fromKotlin/nkutils';
import Agent from './agent';
import {DriverOpt} from 'views/customer/route-map/OrderOptimization';
import {groupByUnStr} from "../api/customer/analytics.api";

export interface OrderData {
    checked?: boolean;
    orderId?: string;
    date?: string;
    notes?: string;
    category?: string;
    status?: string;
    id?: string;
    externalId?: string;
    customer?: string;
    agent?: string;
    driverName?: string;
    car?: string;
    driverId?: number;
    collectorName?: string;
    collectorId?: number;
    mName?: number;
    dvs?: ProductNote[];
    position?: number;
    min_order?: number;
    tn?: boolean;
    driver_position?: number;
    collector_position?: number;
    readonly: boolean;
    all_dvs_printed?: boolean;
    break_row?: boolean;
    city?: string
    c_category?: string
    notes2?: string
    notes3?: string
    notes4?: string
    notes5?: string
    last_created?: string
    last_updated?: string
    ref_id?: string
    collectorData?: string
    nkObject?: any
}

export const createOrderDataEasy = (d: OrderData, orderId?: string, readOnly?: boolean) => {
    const curData = createOrderData({
        orderId: orderId ?? d.orderId,
        id: d.id!,
        customer: d.customer,
        agent: d.agent,
        driverName: d.driverName,
        position: d.position,
        collectorName: d.collectorName,
        driver_position: d.driver_position,
        collector_position: d.collector_position,
        driver_id: d.driverId,
        collector_id: d.collectorId,
        mName: d.mName,
        min_order: d.min_order,
        tn: d.tn,
        readonly: readOnly ?? false,
        city: d.city,
        c_category: d.c_category,
        car: d.car,

    });

    curData.externalId = d.externalId
    return curData
}
export const createOrderData: ({
                                   orderId,
                                   id,
                                   customer,
                                   agent,
                                   driverName,
                                   collectorName,
                                   mName,
                                   customerObj,
                                   position,
                                   driver_position,
                                   collector_position,
                                   checked,
                                   dvs,
                                   readonly,
                                   collector_id,

                                   driver_id,
                                   min_order,
                                   tn,
                                   city,

                                   c_category,
                                   car,

                               }: {
    orderId?: string;
    id?: string;
    customer?: string;
    agent?: string;
    driverName?: string;
    collectorName?: string;
    mName?: number;
    customerObj?: Customer;
    position?: number
    driver_position?: number
    collector_position?: number
    checked?: boolean
    dvs?: ProductNote[],
    readonly: boolean,
    collector_id?: number,
    driver_id?: number,
    min_order?: number
    tn?: boolean,
    city?: string
    c_category?: string,
    car?: string
}) => OrderData = ({
                       orderId = '',
                       id = '',
                       customer = '',
                       agent = '',
                       driverName = '',
                       collectorName = '',
                       mName = undefined,
                       customerObj = undefined,
                       position = 0,
                       driver_position = 0,
                       collector_position = 0,
                       checked = false,
                       dvs = undefined,
                       readonly = false,

                       collector_id = -1,
                       driver_id = -1,
                       min_order = undefined,
                       tn = undefined,
                       city = undefined,
                       c_category = undefined,
                       car = undefined,

                   }) => ({
    orderId,
    id,
    customer,
    agent,
    driverName,
    collectorName,
    mName,
    customerObj,
    position,
    driver_position,
    collector_position,
    checked,
    dvs,
    readonly,
    collector_id,
    driver_id,
    min_order,
    tn, city,
    c_category,
    car,

});
export const writeOrdersToOD = (curData: OrderData, order: ProductNote, writeOrder: boolean = true, active_products?, noComments?, date?: string) => {
    if (writeOrder) {
        curData.orderId = order.id.toString();
        curData.status = order.orderStatus;
        curData.ref_id = order.ref_id;
        curData.nkObject = order.nkObject
    }
    curData.notes = order.comment;
    curData.notes2 = order.notes2;
    curData.notes3 = order.notes3;
    curData.notes4 = order.notes4;
    curData.notes5 = order.notes5;
    curData.category = order.category;
    if (!noComments) {

        curData.last_updated = order?.nkObject?.action_time ? StringToIsrael(order?.nkObject?.action_time) : undefined
        curData.last_created = order?.nkObject?.date_issued ? StringToIsrael(order?.nkObject?.date_issued) : undefined;
        curData.orderDriver = order.agent_id && order.agent_id != -1 ? JSDB().getAgentBy(order.agent_id, null, false, false, false, true)?.first?.user_name : undefined;
        curData.collectorData = order?.nkObject?.getKartonString()
        const karLst = order?.nkObject?.getKartonLst()
        if (karLst && karLst.size > 0) {
            karLst.toArray().forEach((k) => {
                curData[`c_${k.id}`] = k.box
            })
            if (JSDB().getCompany() == 'misrael') {
                const x = JSDB().getKartonAutoCollection(order.nkObject.delivery_info)
                if (x.size > 0) {
                    x.toArray().forEach((k) => {
                        curData[`c_${k.id}`] = k.box
                    })
                }
            }
        }

    }
    const client = JSDB().getClient(order.nkObject.ent_id).first
    const pds = []
    order.products.forEach((op) => {
        if (!active_products || (active_products.has(op.id)) && (!date || client.getPrice(op.id).get(date).first != 0)) {
            pds.push(op.nkObject)
            if (op.available_units.length == 2) {
                let value = 0;
                let item = op;
                let unit = 0;
                if (op.quantity > 0)
                    value = op.quantity;
                else if (op.quantitySecond > 0) {
                    if (item.available_units.length == 2) {
                        unit = 1;
                        value = op.quantitySecond;
                    } else {
                        if (op.conversion_ratio > 0) {
                            value = op.quantity + op.quantitySecond * op.conversion_ratio;
                        }
                    }
                }
                curData[`u_${op.id}`] = unit;
                curData[`p_${op.id}`] = value ?? 0;
            } else {
                curData[`p_${op.id}`] = op.quantity ?? 0;
            }
            if (op.nkObject?.price && op.nkObject?.use_price > 0) {
                curData[`price_${op.id}`] = op.nkObject?.price;
                curData[`discount_${op.id}`] = op.nkObject?.discount;
            }

            curData[`np_${op.id}`] = op.nkObject?.notes;
        }
    });
    curData.karton = roundDecimals(JSDB().kartonEstimate(NKTypeConvertor().toKotlinList(pds)));
    order.history?.forEach((op) => {
        if (!active_products || (active_products.has(op.id)) && (!date || client.getPrice(op.id).get(date).first != 0)) {
            if (op.available_units.length == 2) {
                let value = 0;
                let item = op;
                let unit = 0;
                if (op.quantity > 0)
                    value = op.quantity;
                else if (op.quantitySecond > 0) {
                    if (item.available_units.length == 2) {
                        unit = 1;
                        value = op.quantitySecond;
                    } else {
                        if (op.conversion_ratio > 0) {
                            value = op.quantity + op.quantitySecond * op.conversion_ratio;
                        }
                    }
                }
                curData[`hu_${op.id}`] = unit;
                curData[`hp_${op.id}`] = value ?? 0;
            } else {
                curData[`hp_${op.id}`] = op.quantity ?? 0;
            }
        }
    });

};
export const orderKeyLite = (curData: OrderData) => {
    return `${curData.id}_${curData.orderId}`
};
export const splitOrderKeyLite = (key) => {
    const k = key.split('_');
    return `${key[0]}_${k[1] ?? ''}`
};
export const orderKey = (curData: OrderData) => {
    return orderKeyRef(curData)

};
export const orderKeyRef = (curData: OrderData) => {
    return `${curData.id}_${curData.orderId}_${curData.ref_id ?? ''}`;

};
export const extractIdFromKey = (key: string) => {
    return key.split('_')[0];
}
export const writeDVsToOD = (curData: OrderData, dv: ProductNote[]) => {
    curData.dvs = dv;
    curData.readonly = true;
    curData.all_dvs_printed = dv.every((s) => s.pdf);
    dv.forEach((curNote) => curNote.products?.forEach((op) => {
        const mainQ = op.quantity + op.quantitySecond * op.conversion_ratio;
        if (curData[`n_${op.id}`])
            curData[`n_${op.id}`] += mainQ;
        else
            curData[`n_${op.id}`] = mainQ;
        if (curData[`sn_${op.id}`])
            curData[`sn_${op.id}`] += op.quantitySecond;
        else
            curData[`sn_${op.id}`] = op.quantitySecond;
    }));
};
export const getKartonNum = (d: OrderData): number => {
    return roundDecimals(JSDB().kartonEstimate(deserializeOrderDataToOrder(d)));
};
export const getSumProducts = (d: OrderData): number => {
    let s = 0
    Object.keys(d).forEach((p) => {
        if (p.startsWith('p_') && d[p]) {
            s += d[p]
        }
    });
    return roundDecimals(s);
};
export const deserializeOrderDataToClientDaily = (d: OrderData, date: string, day: number): any => JSDB().createDailyData(date, Number(d.id), d.driverName ?? '', d.collectorName ?? '', d.driver_position, d.collector_position, d.mName ?? 0, day, d.car ?? "");
export const deserializeCustDataToClientDaily = (d: ICustomer, position: number, col_position: number, date: string): any => JSDB().createDailyData(date, Number(d.id), d.driverId ?? '', d.collectorId ?? '', position, col_position, d.crateId ?? 0);
export const deserializeOrderDataToOrder = (d: OrderData): any => {
    const ret: any = [];
    Object.keys(d).forEach((p) => {
        if (p.startsWith('p_') && (Number(d[p] || d.keep))) {
            const id = Number(p.split('_')[1]);
            const pd = JSDB().createProductDelivery(id);
            if (d[`u_${id}`]) {
                pd.wrapped_amount = d[p]
                pd.conversion_ratio = JSDB().getClientProduct(id).first?.getOrderProduct()?.conversion_rate ?? 0
            } else {
                pd.value = d[p]
            }
            if (d[`price_${id}`]) {
                pd.price = d[`price_${id}`]
            }
            if (d[`discount_${id}`]) {
                pd.discount = d[`discount_${id}`]
            }
            if (d[`np_${id}`]) {

                pd.notes = d[`np_${id}`].replace("\"", "").replace("\'", "").replace("\n", "")
            }
            ret.push(pd);
        }
    });
    return NKTypeConvertor().toKotlinList(ret);
};
export const deserializeOrderDataToDV = (d: OrderData): any => {
    const ret: any = [];
    Object.keys(d).forEach((p) => {
        if (p.startsWith('n_') && d[p] !== 0) {
            const id = Number(p.split('_')[1]);
            const pd = JSDB().createProductDelivery(id);
            if (d[`sn_${id}`]) {
                pd.wrapped_amount = d[`sn_${id}`] ?? 0
                pd.conversion_ratio = JSDB().getClientProduct(id).first?.getOrderProduct()?.conversion_rate ?? 0
            } else {
                pd.value = d[p] ?? 0
            }
            ret.push(pd);
        }
    });
    return NKTypeConvertor().toKotlinList(ret);
};
export const deserializeDataStruct = (d: any[]): any => {
    const ret: any = [];
    d.forEach((p) => {
        if (p.amount_dv > 0 || p.amount_second_dv > 0) {
            const pd = JSDB().createProductDelivery(p.p_id);
            pd.value = p.amount_dv;
            if (p.price_change)
                pd.price = p.price_change;
            pd.wrapped_amount = p.amount_second_dv ?? 0;
            pd.conversion_ratio = p.conversion_rate ?? 0;
            if (p.notes) {
                pd.notes = (p.notes ?? '').replace("\"", "").replace("\'", "").replace("\n", "");

            }

            ret.push(pd);
        }
    });
    return NKTypeConvertor().toKotlinList(ret);
};
const colors = ['#FF0000', '#adac41', '#9a1eb0', '#3153ff', '#23FF23', '#000000', '#4fb038']
export const orderDataToMapOptimization = (d: any[], drivers: Agent[]): any => {
    const driversO: DriverOpt[] = [];
    const agents = new Set();
    const real_orders = d.filter((o) => o.orderId && o.orderId != '')
    real_orders.forEach((p) => {
        if (p.driverName) {
            agents.add(p.driverName);
        }
    })
    const groups = groupByUnStr(real_orders, (o) => o.driverName)
    Object.keys(groups).forEach((g, index) => {
        const driver = drivers.find((d) => d.name == g)
        if (driver) {
            const orders = groups[g].map((o) => {
                const entId = o.id
                const orderId = Number(o.orderId)
                const name = o.customer
                const position = o.driver_position
                const client = JSDB().getClient(Number(entId)).first;
                const address = client?.street && client?.streetNumber ? `${client?.street}, ${client?.streetNumber}` : client?.street;
                const full_address = client?.city && address ? `${client?.city} ${address}` : client?.city;
                const location = client?.location && client.location != '' ? client.location.split(',').map((l) => Number(l)) : undefined;
                return {
                    id: orderId,
                    entId: entId,
                    position: position,
                    name: name,
                    address: full_address,
                    location: location ? {lat: location[0], lng: location[1]} : undefined,
                    timeWindow: {start: 0, end: 86400},
                    fixed: false,
                    delay: 0,
                    order: o,
                    estimatedTime: '??:??'

                }

            }).sort((a, b) => a.position - b.position)
            const startLoc = driver?.start_point && driver?.start_point != '' ? driver?.start_point.split(',').map((l) => Number(l)) : undefined
            const endLoc = driver?.end_point && driver?.end_point != '' ? driver?.end_point.split(',').map((l) => Number(l)) : undefined
            driversO.push({
                color: colors[index % colors.length],
                delayBetweenPoints: 10,
                endPosition: endLoc ? {lat: endLoc[0], lng: endLoc[1]} : undefined,
                id: 0,
                startPosition: startLoc ? {lat: startLoc[0], lng: startLoc[1]} : undefined,
                startTime: 9 * 60 * 60,
                totalEstimatedTime: 0,
                name: driver.name,
                orders: orders
            })

        }

    })

    return driversO
    // agents.forEach((a) => {
    //
    //     const numbers = data.filter((c) => c.driverName === a.name).sort((a, b) => ((a.driver_position ?? 0) - (b.driver_position ?? 0))).map((c) => Number(c.id));
    //
    //     ag.push(
    //         {
    //             id: a.id, customersIdList: numbers, label: a.name, location: [], driverInitLoc: a.start_point, driverEndLoc: a.end_point
    //
    //
    //         }
    //     );
    //
    // });
};
export const deserializeDataStructOld = (d: any[]): any => {
    const ret: any = [];
    d.forEach((p) => {
        if (p.amount_dv > 0 || p.amount_second_dv > 0) {
            const pd = JSDB().createProductDelivery(p.id);
            pd.value = p.amount_dv;
            pd.price = p.price;
            pd.wrapped_amount = p.amount_second_dv ?? 0;
            pd.conversion_ratio = p.conversion_rate ?? 0;
            ret.push(pd);
        }
    });
    return NKTypeConvertor().toKotlinList(ret);
};
export const spreadAmountRoundRobin = (d: OrderData[], col, amount, activeOnly: boolean = false): any[] => {
    // assuming one has already filtered the data, and d is too be algorithmed
    const sortedData = d.map((v) => v).sort((a, b) => (b[col] ?? 0) - (a[col] ?? 0));
    const change: any[] = sortedData.map((m) => ({
        id: m.id,
        value: m[col] ?? 0
    }));
    roundRobinFull(change, amount, activeOnly, 6);
    return change;

};

interface result {
    value: any[];
    missing: number;
}

export const roundRobinFull = (change: any[], toSpread: number, activeOnly: boolean = false, tries: number = 3): result => {
    // assuming one has already filtered the data, and d is too be algorithmed
    let missed = toSpread;
    let chager = activeOnly ? change.filter((d) => d.value && d.value != 0) : change;
    while (tries > 0 && missed != 0) {
        const result = roundRoundRobin(chager, missed);
        missed = result.missing;
        chager = result.value;
        tries -= 1;
    }
    return {value: change, missing: missed};
};
export const roundRoundRobin = (change: any[], toSpread: number): result => {
    const chager = toSpread >= 0 ? change : change.filter((d) => d.value && d.value > 0);
    const size = chager.length;
    const spreadEach = toSpread > 0 ? Math.floor(toSpread / size) : Math.ceil(toSpread / size);
    let extra = toSpread - spreadEach * size;
    chager.forEach((sd) => {
        if (toSpread >= 0) {
            if (sd.value)
                sd.value += spreadEach;
            else
                sd.value = spreadEach;
            if (extra > 0) {
                sd.value += 1;
                extra -= 1;
            }
        } else {
            if (sd.value) {
                if (sd.value + spreadEach < 0) {
                    extra += (sd.value + spreadEach);
                    sd.value = 0;
                } else {
                    sd.value += spreadEach;
                }
                if (extra < 0 && sd.value > 0) {
                    sd.value -= 1;
                    extra += 1;
                }
            }
        }
    });
    return {value: change, missing: extra};
};
export const spreadPercentage = (change: any[], have: number, toReach: number): result => {
    // assuming one has already filtered the data, and d is too be algorithmed
    let cur = 0;
    change.forEach((sd) => {
        sd.value = Math.floor((sd.value / have) * toReach);
        cur += sd.value;
    });


    return {value: change, missing: toReach - cur};

};
export const spreadAmountStronger = (d: OrderData[], col, amount): OrderData[] => {
    // assuming one has already filtered the data, and d is too be algorithmed
    const sortedData = d.map((v) => v).sort((a, b) => (b[col] ?? 0) - (a[col] ?? 0));
    const sumFunc = sortedData.reduce((sum, x) => sum + (x[col] ?? 0), 0) ?? 0;
    let change: any[] = sortedData.map((m) => ({
        id: m.id,
        value: m[col] ?? 0
    }));
    const result = spreadPercentage(change, sumFunc, amount);
    if (result.missing > 0) {
        const withValue = change.filter((sd) => sd.value > 0);
        roundRobinFull(withValue, result.missing, true, 2);

    }


    return change;

};





